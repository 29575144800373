import { t } from '@lib/i18n';
import { graphql, StaticQuery } from 'gatsby';

import VersionsLayout from '@routes/versions/ui/layout/VersionsLayout';

// ----------------------------------------------------------------------------

const query = graphql`
  {
    allMarkdownRemark(
      filter: {frontmatter: {type: {eq: "release"}}}
      limit:2
      sort: {fields: frontmatter___createdAt, order: DESC}
    ) {
      edges {
        node {
          htmlAst
          frontmatter {
            version
            slug
            type
            language
            createdAt
          }
        }
        next {
          frontmatter {
            slug
            seo {
              link
            }
          }
        }
      }
    }
  }
`;

const VersionsPage = () => {
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        if (data.allMarkdownRemark.edges.length === 0) {
          return (
            <VersionsLayout
              noVersions
              frontmatter={{ seo: t('seo.versions') }}
            />
          );
        }
        const [currentVersion] = data.allMarkdownRemark.edges;
        const { node: { htmlAst, frontmatter }, next: previous } = currentVersion;
        return (
          <VersionsLayout
            htmlAst={htmlAst}
            frontmatter={{ ...frontmatter, seo: t('seo.versions') }}
            isCurrentVersion
            previousVersion={previous}
            banner
          />
        );
      }}
    />
  );
};

export default VersionsPage;
